export const FREESOUND_API_KEY='psCNi4Erp8PWNSSsiZUQ6uMBxst1xdOjBoEej7gy';
export const TEMPO_MIN=60;
export const TEMPO_MAX=90;
export const BEATS_IN_MEASURE=4;
export const TUNING_FREQ=440;
export const MS_IN_MINUTE=60000;
export const INSTRUMENTS=[
  'piano',
  'low voice',
  'high voice',
  'guitar',
  'bass clarinet',
  'bari sax',
  'soprano sax',
  'double bass',
  'bassoon',
  'cello',
  'violin',
  'singing bowl',
  'choir',
  'xylophone'
]